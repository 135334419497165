@import '~bootstrap/scss/bootstrap';
@import '~bootstrap/scss/bootstrap-utilities';
@import '~react-toastify/scss/main';
@import '~react-loading-skeleton/dist/skeleton.css';

.cursor-pointer {
  cursor: pointer;
}

.flex-grow-2 {
  flex-grow: 2 !important;
}

.flex-grow-3 {
  flex-grow: 3 !important;
}

.width-0 {
  width: 0;
}

.width-20 {
  width: 20px;
}

.width-120 {
  width: 120px;
}

.width-200 {
  width: 200px;
}

.width-1em {
  width: 1.5em;
}

.height-1em {
  height: 1.5em;
}

.height-0 {
  height: 0;
}

.height-40 {
  height: 40px;
}

.fs-12px {
  font-size: 12px;
}

.h-100vh {
  height: 100vh;
}

.text-pre-line {
  white-space: pre-line
}

.border-dotted {
  border-style: dotted;
}

.search-input {
  border-radius: 4px;
  border-color: rgb(204, 204, 204);
}

.spin {
  transform-origin: center;
  animation:spin .7s linear infinite
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.size-20-hover {
  margin-left: 2px;
  margin-right: 2px;
}

.size-20-hover:hover {
  margin-left: 0;
  margin-right: 0;
  width: 20px;
  height: 20px;
}
