.month-picker-popup {
  width: 350px;
  z-index: 10;
  top: 38px;
}

.months-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(4, 1fr);
  gap: 5px 0;
}