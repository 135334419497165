.grid-cell {
  outline: 1px solid rgb(222, 226, 230);
  padding: 0.5em;
  background-color: #f0f0f0;
}

.grid-table {
  grid-template-columns: max-content max-content minmax(80px, auto) minmax(75px, max-content);

  > .grid-cell:nth-child(8n+1), .grid-cell:nth-child(8n+2), .grid-cell:nth-child(8n+3), .grid-cell:nth-child(8n+4),
  {
    background-color: #fff;
  }
}

.grid-table-with-date {
  grid-template-columns: max-content max-content max-content minmax(80px, auto) minmax(75px, max-content);

  .grid-cell:nth-child(10n+1), .grid-cell:nth-child(10n+2), .grid-cell:nth-child(10n+3), .grid-cell:nth-child(10n+4), .grid-cell:nth-child(10n+5) {
    background-color: #fff;
  }
}